import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import AsideLayout from "./AsideLayout/AsideLayout";
import ApiLink from "../../components/api/ApiLink";
import { useSelector } from "react-redux";
import { useSelectedCompany } from "../../components/context/apiContext/SelectedCompanyContext";
import AddDevice from "../../components/modals/Device.js/AddDevice";
import { saveAs } from "file-saver";
import moment from "moment-timezone";

export default function Devices() {
  const swal = require("sweetalert2");

  const [deviceVisualDetails, setDeviceVisualDetails] = useState({
    locationId: null,
    locationName: null,
    companyId: null,
    city: null,
    state: null,
    zip: null,
    address: null,
  });
  const { updateSelectedLocation } = useSelectedCompany();
  // const is_read_only = useState(0);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const [addDevice, setAddDevice] = useState(false);
  const [editDevice, setEditDevice] = useState(false);

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const [companyIds, setCompanyIds] = useState([]);

  const [firmwarePackage, setFirmwarePackage] = useState({});
  const [firmwareVersion, setFirmwareVersion] = useState({});
  const [companyListData, setCompanyListData] = useState([]);
  const [companyLocationData, setCompanyLocationData] = useState([]);

  const [getDeviceData, setGetDeviceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectAccountDropdown, setselectAccountDropdown] = useState(false);
  const [selectLocationDropdown, setSelectLocationDropdown] = useState(false);
  const [selectSortOrderDropdown, setSelectSortOrderDropdown] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const [error, setError] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("Select Location");

  const [accountSearchTerm, setAccountSearchTerm] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("Select Account");

  const [filteredDevices, setFilteredDevices] = useState(getDeviceData);

  const dropdownRef = useRef(null);

  const filteredCompanies = companyListData.filter((company) =>
    company.name.toLowerCase().includes(accountSearchTerm.toLowerCase())
  );

  const [showFilter, setShowFilter] = useState(false);
  const [selectedDeviceIdentifier, setSelectedDeviceIdentifier] =
    useState(null);

  const [deviceIdDetails, setDeviceIdDetails] = useState({
    serial_number: null,
    device_identifier: "",
    type: "",
    model: "",
    status: "",
    modem_number: 0,
    firmware_package_id: "",
    ota_enabled: 0,
    ota_last_request: "",
    firmware: "",
    sim_phone_number: "",
    sim_carrier: "",
    notes: "",
    device_id: null,
  });

  const filterLocationsBySearch = () => {
    // Filter the companyListData based on the search term
    return companyListData.flatMap((company) => {
      const locations = company.company_location_id
        ? JSON.parse(company.company_location_id)
        : [];

      // Filter the locations by the search term (case insensitive)
      const filteredLocations = locations.filter((location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Return the filtered locations mapped to include company information
      return filteredLocations.map((location) => ({
        ...location,
        companyName: company.name,
      }));
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { updateSerialNumber } = useSelectedCompany();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [currentItems, setCurrentItems] = useState([]);

  const [selectedDeviceType, setSelectedDeviceType] = useState("all");

  const [noUserMessage, setNoUserMessage] = useState("");

  const [deviceIds, setDeviceIds] = useState([]);

  const [deviceVisualData, setDeviceVisualData] = useState([]);
  const [mappedDevices, setMappedDevices] = useState([]);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);

  const [currentBatch, setCurrentBatch] = useState(1);
  const batchSize = 20;
  const totalPages = Math.ceil(getDeviceData.length / itemsPerPage);
  const totalBatches = Math.ceil(totalPages / batchSize);
  const startPage = (currentBatch - 1) * batchSize + 1;
  const endPage = Math.min(startPage + batchSize - 1, totalPages);

  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
  const searchRef = useRef(null);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState({
    name: null,
    company_id: null,
  });
  const [selectedSortOrderName, setSelectedSortOrderName] = useState(null);
  const [selectedSortOrder, setSelectedSortOrder] = useState("All");
  const [selectedLocationName, setSelectedLocationName] = useState({
    name: null,
    company_location_id: null,
  });

  const [companydropdownLoading, setCompanydropdownLoading] = useState(false);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);

  const [online, setOnline] = useState(0);
  const [offline, setOffline] = useState(0);
  const [installed, setInstalled] = useState(0);
  const [notInstalled, setNotInstalled] = useState(0);
  const [inactive, setInactive] = useState(0);
  const [testAccount, setTestAccount] = useState(0);

  useEffect(() => {
    ApiLink.get("/api/firmware-package")
      .then((response) => {
        setFirmwarePackage(response.data.FirmwarePackage);
      })
      .catch((error) => {
        console.error("Error fetching firmware package:", error);
      });
  }, [addDevice, editDevice]);

  useEffect(() => {
    ApiLink.get("/api/firmware-version")
      .then((response) => {
        setFirmwareVersion(response.data.FirmwareVersion);
      })
      .catch((error) => {});
  }, [addDevice, editDevice]);

  const fetchGetDeviceData = useMemo(() => {
    return async () => {
      setLoading(true);
      try {
        const requestBody = {
          email,
          password,
          status: selectedStatus,
          //use_cache: false,
          ...(selectedCompanyName.company_id && {
            company_id: selectedCompanyName.company_id,
          }),
          ...(selectedLocationName.company_location_id && {
            company_location_id: selectedLocationName.company_location_id,
          }),
          ...(selectedSortOrderName && {
            sort: selectedSortOrderName,
          }),
          order: "last_hb",
        };

        const response = await ApiLink.post("/api/get-device", requestBody, {
          timeout: 10000, // Set a reasonable timeout
        });

        if (response.status === 200) {
          const { Data, count } = response.data;
          console.log("Count: ", count);
          if (Array.isArray(Data)) {
            // Use batch updates to reduce re-renders
            const updates = {
              getDeviceData: Data,
              online: count.online,
              offline: count.offline,
              installed: count.installed,
              notInstalled: count.not_installed,
              inactive: count.inactive,
              testAccount: count.test,
            };

            Object.entries(updates).forEach(([key, value]) => {
              if (key === "getDeviceData") {
                setGetDeviceData(value);
              } else {
                // Use functional updates for other state setters
                const setter = eval(
                  `set${key.charAt(0).toUpperCase() + key.slice(1)}`
                );
                setter((prevValue) => value);
              }
            });
          } else if (Data.Message) {
            setNoUserMessage(Data.Message);
            setGetDeviceData([]);
          }
        } else {
          console.error("Error:", response.data);
          setGetDeviceData([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setGetDeviceData([]);
        setNoUserMessage(
          error.response?.status === 500
            ? "An internal server error occurred. Please try again later."
            : "An error occurred. Please try again."
        );
      } finally {
        // Remove setTimeout to improve performance
        setLoading(false);
      }
    };
  }, [
    email,
    password,
    selectedStatus,
    selectedCompanyName.company_id,
    selectedLocationName.company_location_id,
    selectedSortOrderName,
  ]);

  const fetchCompanyList = useMemo(() => {
    return async () => {
      try {
        setCompanydropdownLoading(true);
        const response = await ApiLink.post("/api/company-list", {
          email,
          password,
          status: 1,
        });
        if (response.status === 200 && Array.isArray(response.data.Data)) {
          const companyList = response.data.Data;

          // Add "Unassigned" location to the first company's locations
          if (companyList[0].company_location_id) {
            const locations = JSON.parse(companyList[0].company_location_id);
            locations.unshift({ id: 100000, name: "Unassigned" });
            companyList[0].company_location_id = JSON.stringify(locations);
          }
          //console.log("Company List: ", companyList[0].company_location_id);
          setCompanyListData(companyList);
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      } finally {
        setCompanydropdownLoading(false);
      }
    };
  }, [email, password]);

  // Use the memoized function
  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  useEffect(() => {
    fetchGetDeviceData();
  }, [fetchGetDeviceData]);

  const fetchDeviceId = useCallback(async (email, password, device_id) => {
    try {
      const response = await ApiLink.post("/api/get-device-id", {
        email,
        password,
        device_id,
      });

      if (response.status === 200) {
        const { Data } = response.data;
        setDeviceIdDetails((prevDetails) => ({
          ...prevDetails,
          ...Data,
          device_id,
        }));
      }
    } catch (error) {
      console.error("Error fetching device details:", error);
    }
  }, []);

  useEffect(() => {
    //console.log("Device Details: ", deviceIdDetails);
  }, [deviceIdDetails]);

  const toggleEditDevice = (device_id) => {
    setEditDevice(true);
    fetchDeviceId(email, password, device_id);
  };

  const handleEditDeviceSubmit = async (e) => {
    e.preventDefault();
    swal
      .fire({
        title: "Do you want to update this device?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })

      .then(async (result) => {
        try {
          const response = await ApiLink.post("/api/update-device", {
            email: email,
            password: password,
            device_id: deviceIdDetails.device_id,
            serial_number: deviceIdDetails.serial_number,
            device_identifier: deviceIdDetails.device_identifier,
            type: deviceIdDetails.type,
            model: deviceIdDetails.model,
            status: deviceIdDetails.status,
            modem_number: deviceIdDetails.modem_number,
            firmware_package_id: deviceIdDetails.firmware_package_id,
            ota_enabled: deviceIdDetails.ota_enabled,
            ota_last_request: deviceIdDetails.ota_last_request,
            firmware: deviceIdDetails.firmware,
            sim_phone_number: deviceIdDetails.sim_phone_number,
            sim_carrier: deviceIdDetails.sim_carrier,
            notes: deviceIdDetails.notes,
          });

          if (response.status === 200) {
            try {
              const zohoResponse = await ApiLink.post(
                "/api/zoho-crm-update-device",
                {
                  serial_number: deviceIdDetails.serial_number,
                  device_identifier: deviceIdDetails.device_identifier,
                  type: deviceIdDetails.type,
                  model: deviceIdDetails.model,
                  status: deviceIdDetails.status,
                  modem_number: deviceIdDetails.modem_number,
                  firmware_package_id: deviceIdDetails.firmware_package_id,
                  ota_enabled: deviceIdDetails.ota_enabled,
                  ota_last_request: deviceIdDetails.ota_last_request,
                  firmware: deviceIdDetails.firmware,
                  sim_phone_number: deviceIdDetails.sim_phone_number,
                  sim_carrier: deviceIdDetails.sim_carrier,
                  notes: deviceIdDetails.notes,
                  recordid: deviceIdDetails.device_id,
                }
              );

              if (zohoResponse.data.data[0].code === "SUCCESS") {
                console.log("response update: ", response.data);
                swal.fire({
                  title: "Device Updated Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                closeEditModal();
                fetchGetDeviceData();
              }
            } catch (error) {}
          }
        } catch (error) {
          console.log("Error: ", error);
          swal.fire({
            title: "Error Occured in updating device",
            icon: "error",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      });
  };

  const toggleDeleteDevice = (device_id, device_identifier) => {
    console.log("Clicked");
    swal
      .fire({
        title: "Do you want to delete this device?",
        text: `Device "${device_identifier}" will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/delete-device", {
              email: email,
              password: password,
              device_id: device_id,
            });

            if (response.status === 200) {
              swal.fire({
                title: "Device Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchGetDeviceData();
              clearSearchResults();
            } else {
              swal.fire({
                title: "Error Occured. Please try again.",
                icon: "warning",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
            }
          } catch (error) {
            console.log("Error: ", error);
            swal.fire({
              title: "Error Occured. Please try again.",
              icon: "error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const closeEditModal = () => {
    setEditDevice(false);
    setDeviceIdDetails({
      serial_number: null,
      device_identifier: "",
      type: "",
      model: "",
      status: "",
      modem_number: "",
      firmware_package_id: "",
      ota_enabled: 0,
      ota_last_request: "",
      firmware: "",
      sim_phone_number: "",
      sim_carrier: "",
      notes: "",
    });
  };

  const toggleselectAccountDropdown = () => {
    setselectAccountDropdown((prevState) => !prevState);
  };

  const toggleSelectLocationDropdown = () => {
    setSelectLocationDropdown((prevState) => !prevState);
  };
  const toggleSelectSortOrderDropdown = () => {
    setSelectSortOrderDropdown((prevState) => !prevState);
  };

  const handleSearchLocationData = (company_id) => {
    console.log("Company ID:", company_id);
  };

  const DEVICE_TYPES = {
    0: "Raspberry Pi",
    1: "(S3) Renew Battery",
    2: "(S3) Home",
  };

  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
    sideBarOpen,
  } = useContext(ThemeContext);
  const getColorCode = (color) => {
    switch (color) {
      case "blue":
        return "#1c64f2";
      case "green":
        return "#057a55";
      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleSerialNumberClick = (deviceId) => {
    updateSerialNumber({ deviceId });
  };

  const nextBatch = () => {
    if (currentBatch < totalBatches) {
      setCurrentBatch((prev) => prev + 1);
    }
  };

  const prevBatch = () => {
    if (currentBatch > 1) {
      setCurrentBatch((prev) => prev - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  useEffect(() => {
    const lowerSearchInput = searchInput.toLowerCase();
    const filtered = getDeviceData.filter((device) => {
      const serialNumber = device.serial_number.toLowerCase();
      const deviceIdentifier = device.device_identifier?.toLowerCase();
      return (
        serialNumber.includes(lowerSearchInput) ||
        (deviceIdentifier && deviceIdentifier.includes(lowerSearchInput))
      );
    });
    setFilteredDevices(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  }, [searchInput, getDeviceData]);

  const handleSearchInputs = (e) => {
    const input = e.target.value;
    setSearchInput(input);
  };
  const handleResultClick = (page, serialNumber, deviceIdentifier) => {
    goToPage(page);
    setSelectedSerialNumber(serialNumber);
    setSelectedDeviceIdentifier(deviceIdentifier);
    setSearchInput("");
    setSearchResults([]);
  };

  const getFirmwareVersion = (firmwarePackageId) => {
    if (firmwarePackageId === null || firmwarePackageId === undefined) {
      return "?";
    }

    const matchingEntry = Object.entries(firmwarePackage).find(
      ([key, value]) => key === firmwarePackageId.toString()
    );

    if (matchingEntry) {
      return matchingEntry[1].split("/")[1].split("-")[1];
    }
    return "?";
  };

  useEffect(() => {
    const sortedDevices = sortDevices(getDeviceData);
    setGetDeviceData(sortedDevices);
  }, [getDeviceData, sortOrder]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newCurrentItems = filteredDevices.slice(startIndex, endIndex);

    // Use a more efficient comparison method
    if (!areArraysEqual(newCurrentItems, currentItems)) {
      setCurrentItems(newCurrentItems);
    }
  }, [filteredDevices, currentPage, itemsPerPage, currentItems]);

  // Helper function for efficient array comparison
  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  function formatDate(dateString) {
    if (dateString === "0000-00-00 00:00:00") {
      return "";
    }
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensures two-digit minutes
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
    return formattedDate;
  }

  const getBackgroundColorStyle = (lastHb) => {
    const currentTime = moment().tz("America/New_York");

    // Assume lastHb is already in Eastern Time, so parse it directly
    const lastHbTime = moment.tz(
      lastHb,
      "YYYY-MM-DD HH:mm:ss",
      "America/New_York"
    );

    const timeDifference = currentTime.diff(lastHbTime, "minutes");

    // console.log("Current Time:", currentTime.format());
    // console.log("Last HB Time:", lastHbTime.format());
    // console.log("Time Difference:", timeDifference, "minutes");

    if (timeDifference <= 12) {
      return { backgroundColor: "#62cb30" }; // Custom green color within 12 hours
    } else if (timeDifference > 12 && timeDifference <= 24) {
      return { backgroundColor: "orange" }; // More than 12 hours but less than or equal to 24 hours
    } else if (timeDifference > 24) {
      return { backgroundColor: "red" }; // More than 24 hours
    } else {
      return { backgroundColor: "gray" };
    }
  };

  const sortDevices = (devices) => {
    return devices.sort((a, b) => {
      if (sortOrder === "ASC") {
        return new Date(a.last_hb) - new Date(b.last_hb);
      } else {
        return new Date(b.last_hb) - new Date(a.last_hb);
      }
    });
  };

  useEffect(() => {
    if (selectedCompanyName.company_id !== null) {
      fetchGetDeviceData();
    }
  }, [selectedCompanyName.company_id, fetchGetDeviceData]);

  const handleLocationClick = useCallback(
    (device_id) => {
      const fetchDeviceVisual = async () => {
        try {
          const response = await ApiLink.post("/api/device-visual", {
            email,
            password,
            device_id,
          });

          if (response.status === 200) {
            const {
              company_location_id: locationId,
              name: locationName,
              company_id: companyId,
              city,
              state,
              zip,
              address_line1: address,
            } = response.data.Data.locationInfo;

            const locationDetails = {
              locationId,
              locationName,
              companyId,
              city,
              state,
              zip,
              address,
            };

            setDeviceVisualDetails(locationDetails);
            updateSelectedLocation(locationDetails);

            // Remove console.log in production for better performance
            if (process.env.NODE_ENV !== "production") {
              console.log("deviceVisualDetails:", locationDetails);
            }
          }
        } catch (error) {
          console.error("Error fetching device visual:", error);
        }
      };

      fetchDeviceVisual();
    },
    [email, password, setDeviceVisualDetails, updateSelectedLocation]
  );

  const clearSearchResults = () => {
    setSearchInput("");
    setSearchResults([]);
    setSelectedSerialNumber(null);

    // Clear selectedCompanyName to null
    setSelectedCompanyName({
      name: null,
      company_id: null,
    });

    setSelectedLocationName({
      name: null,
      company_location_id: null,
    });

    setSelectedSortOrderName(null);
    setSelectedSortOrder("All");
    setSelectedCompany("Select Account");
    setSelectedLocation("Select Location");

    // Refetch data
    fetchGetDeviceData({ email: email, password: password });
  };

  const handleSortChange = (e) => {
    const selectedValue = e.target.value;
    toggleSelectSortOrderDropdown();
    setSelectedSortOrderName(selectedValue);
    setSortOrder(selectedValue);
    if (selectedValue === "") {
      setItemsPerPage(10);
    } else {
      setItemsPerPage(1000);
    }
    setCurrentPage(1);
  };

  const handleDeviceType = (e) => {
    const selectedValue = e.target.value;
    setSelectedDeviceType(selectedValue);
    if (selectedValue === "all") {
      setItemsPerPage(10);
    } else {
      setItemsPerPage(1000);
    }
    setCurrentPage(1);
  };

  const handleCompanyClick = (name, company_id) => {
    console.log("SELECTED COMPANY LOCATION: ", name, company_id);
    setSelectedCompanyName({
      name,
      company_id,
    });
    toggleSelectLocationDropdown();
    setSelectedCompany(name);
  };

  const handleLocationSearch = (company_location_id, locationName) => {
    console.log("SELECTED LOCATION: ", company_location_id, locationName);

    // Special handling for Unassigned location
    if (company_location_id === 100000) {
      setSelectedLocationName({
        name: "Unassigned",
        company_location_id: 100000,
      });
      setSelectedLocation("Unassigned"); // Update dropdown display text
    } else {
      setSelectedLocationName({
        name: locationName,
        company_location_id: company_location_id,
      });
      setSelectedLocation(locationName); // Update dropdown display text
    }
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectLocationDropdown(false);
        setselectAccountDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(addDevice || editDevice);
    return () => toggleBodyScroll(false);
  }, [addDevice, editDevice]);

  const toggleAddDevice = () => {
    setAddDevice(true);
  };

  const toggleRestoreDevice = (device_id, device_identifier) => {
    swal
      .fire({
        title: "Do you want to restore this device?",
        text: `Device "${device_identifier}" will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/restore-device", {
              email: email,
              password: password,
              device_id: device_id,
            });
            if (response.status === 200) {
              if (response.data.Data.success == true) {
                swal.fire({
                  title: "Device Restored Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchGetDeviceData();
                clearSearchResults();
              } else if (response.data.Data.success === false) {
                swal.fire({
                  title: "Error Occured. Please try again later.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else {
                swal.fire({
                  title: "Unknown Error Occured. Please try again later.",
                  icon: "error",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              }
            }
          } catch (error) {}
        }
      });
  };

  useEffect(() => {
    console.log("selectedStatus: ", selectedStatus);
  }, [selectedStatus]);

  const handleDownloadDevice = () => {
    if (getDeviceData.length === 0) {
      alert("No data available to download");
      return;
    }

    const headers = [
      "Device ID",
      "Type",
      "Serial Number",
      "Device Identifier",
      "Model",
      "Status",
      "Last Heartbeat",
      "Last Schedule",
      "Last IP",
      "SIM Phone Number",
      "Last Phone",
      "Notes",
      "OTA Enabled",
      "OTA Last Request",
      "Firmware",
      "Last Firmware",
      "Firmware Package ID",
      "Modem Number",
      "SIM Carrier",
      "Company Name",
      "Is Test Account",
      "Location Name",
      "Location Device ID",
      "Location Device Name",
      "Has Flow Sensor",
      "Timer Type",
    ];

    let csvContent = headers.join(",") + "\n";

    const statusMap = {
      0: "Disabled",
      1: "Active",
      2: "Pending",
      3: "Deleted",
    };

    const otaEnabledMap = {
      0: "No",
      1: "Yes",
    };

    const isTestAccountMap = {
      0: "No",
      1: "Yes",
    };

    const hasFlowSensor = {
      0: "No",
      1: "Yes",
    };

    const TimerType = {
      0: "No",
      1: "Yes",
    };

    const sim_carrier_map = {
      0: "Verizon",
      1: "T-Mobile",
      2: "Sprint",
      3: "AT&T",
    };

    const DEVICE_TYPES = {
      0: "Raspberry Pi",
      1: "(S3) Renew Battery",
      2: "(S3) Home",
    };

    getDeviceData.forEach((device) => {
      const row = [
        device.device_id,
        DEVICE_TYPES[device.type] || device.type,
        device.serial_number,
        device.device_identifier,
        device.model,
        statusMap[device.status] || device.status, // Map status to text
        device.last_hb,
        device.last_schedule,
        device.last_ip,
        device.sim_phone_number,
        device.last_phone,
        device.notes,
        otaEnabledMap[device.ota_enabled] || device.ota_enabled, // Map OTA Enabled to text
        device.ota_last_request,
        device.firmware,
        device.last_firmware,
        device.firmware_package_id,
        device.modem_number,
        sim_carrier_map[device.sim_carrier] || device.sim_carrier,
        device.company_name,
        isTestAccountMap[device.is_test_account] || device.is_test_account, // Map is_test_account to text
        device.location_name,
        device.location_device_id,
        device.location_device_name,
        hasFlowSensor[device.has_flow_sensor] || device.has_flow_sensor,
        TimerType[device.timer_type] || device.timer_type,
      ];

      const escapedRow = row.map((field) => {
        if (field === null || field === undefined) return "";

        let cleanField = String(field)
          .replace(/"/g, '""') // Escape double quotes by doubling them
          .replace(/[\n\r]+/g, " "); // Replace newlines with spaces

        return cleanField.includes(",") ||
          cleanField.includes('"') ||
          cleanField.includes("\n")
          ? `"${cleanField}"`
          : cleanField;
      });

      csvContent += escapedRow.join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const date = new Date().toISOString().slice(0, 10);
    const filename = `devices_${date}.csv`;

    saveAs(blob, filename);
  };
  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20 relative">
          {" "}
          <div className="p-4 shadow-md bg-white rounded-md dark:bg-gray-800">
            <div
              className={`list flex flex-row flex-wrap justify-end items-center gap-3 text-sm font-inter`}
            >
              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#62cb31] text-white text-xs font-semibold rounded">
                  {online}
                </h1>
                <label className="font-medium dark:text-white">Online</label>
              </div>
              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#ff1911] text-white text-xs font-semibold rounded-md">
                  {offline}
                </h1>
                <label className="font-medium dark:text-white">Offline</label>
              </div>
              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#38940d] text-white text-xs font-semibold rounded">
                  {installed}
                </h1>
                <label className="font-medium dark:text-white">Installed</label>
              </div>

              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#F0AD4E] text-white text-xs font-semibold rounded">
                  {notInstalled}
                </h1>
                <label className="font-medium dark:text-white">
                  Not Installed
                </label>
              </div>
              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#D9534F] text-white text-xs font-semibold rounded-md">
                  {inactive}
                </h1>
                <label className="font-medium dark:text-white">Inactive</label>
              </div>
              <div className="flex items-center gap-1">
                <h1 className="px-1 py-0.5 bg-[#337AB7] text-white text-xs font-semibold rounded-md">
                  {testAccount}
                </h1>
                <label className="font-medium dark:text-white">Test</label>
              </div>
            </div>

            <form
              onSubmit={handleSearchSubmit}
              className="mx-auto flex justify-between items-center pb-3 mt-12"
            >
              <div className="filterContainer hidden xl:flex gap-4 pr-2 mr-4 ">
                <div className="w-40">
                  <label
                    htmlFor="status"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Status
                  </label>
                  <select
                    id="status"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={selectedStatus}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setSelectedStatus(selectedValue);
                      setCurrentPage(1);

                      if (selectedValue === "") {
                        clearSearchResults();
                      }
                    }}
                    disabled={deviceLoading || loading}
                  >
                    <option value="">Any</option>
                    <option value="1">Enabled</option>
                    <option value="2">Pending</option>
                    <option value="0">Disabled</option>
                    <option value="3">Deleted</option>
                  </select>
                </div>

                <div className="w-40">
                  <label
                    htmlFor="location"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Account
                  </label>
                  <button
                    id="selectAccountDropdown"
                    className="w-40 bg-gray-50 border border-gray-300 text-black dark:text-white dark:bg-gray-700 dark:border-gray-600 focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm rounded-md px-5 py-2.5 inline-flex items-center justify-between"
                    type="button"
                    onClick={toggleSelectLocationDropdown}
                  >
                    {companydropdownLoading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <span className="truncate">{selectedCompany}</span>
                        <svg
                          className="w-2.5 h-2.5 ml-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                  {selectLocationDropdown && (
                    <div
                      id="dropdownSearch"
                      className="z-10 absolute bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                      ref={dropdownRef}
                    >
                      <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-600 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="input-group-search"
                            className="w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search Account"
                            onChange={(e) =>
                              setAccountSearchTerm(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <ul
                        className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownSearchButton"
                      >
                        {filteredCompanies.map((company) => (
                          <li key={company.company_id}>
                            <button
                              className="flex items-center w-full ps-2 py-2 text-sm font-medium text-black rounded hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
                              onClick={() =>
                                handleCompanyClick(
                                  company.name,
                                  company.company_id
                                )
                              }
                            >
                              {company.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="w-40">
                  <label
                    htmlFor="selectAccountDropdown"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Location
                  </label>
                  <button
                    id="selectAccountDropdown"
                    className="w-40 bg-gray-50 border border-gray-300 text-black dark:text-white dark:bg-gray-700 dark:border-gray-600 focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm rounded-md px-5 py-2.5 inline-flex items-center justify-between"
                    type="button"
                    onClick={toggleselectAccountDropdown}
                  >
                    {companydropdownLoading ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <span className="truncate">{selectedLocation}</span>
                        <svg
                          className="w-2.5 h-2.5 ml-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                  {selectAccountDropdown && (
                    <div
                      id="dropdownSearch"
                      className="z-10 absolute bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                      ref={dropdownRef}
                    >
                      <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                              className="w-4 h-4 text-gray-600 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                              />
                            </svg>
                          </div>
                          <input
                            type="text"
                            id="input-group-search"
                            className="w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search Location"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                          />
                        </div>
                      </div>

                      {/* Filter and display the locations */}
                      <ul
                        className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownSearchButton"
                      >
                        {filterLocationsBySearch().map((location) => (
                          <li key={location.id}>
                            <div
                              className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                              onClick={() => {
                                // Don't update selected company name if it's the Unassigned location
                                if (location.id !== 100000) {
                                  setSelectedLocation(location.companyName);
                                }
                                toggleselectAccountDropdown();
                                handleLocationSearch(
                                  location.id,
                                  location.name
                                ); // Handle location search
                              }}
                            >
                              <label className="w-full py-2 ms-2 text-sm font-medium text-black rounded dark:text-white">
                                {location.name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="w-40">
                  <label
                    htmlFor="sortByHb"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Sort by HB
                  </label>
                  <select
                    id="sortByHb"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={sortOrder}
                    onChange={handleSortChange}
                    disabled={deviceLoading || loading}
                  >
                    <option value="">All</option>
                    <option value="ASC">Ascending</option>
                    <option value="DESC">Descending</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="deviceType"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Device Type
                  </label>
                  <select
                    id="deviceType"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={handleDeviceType}
                    disabled={deviceLoading || loading}
                    value={selectedDeviceType}
                  >
                    <option value="all">All Device Types</option>
                    <option value="0">Raspberry Pi</option>
                    <option value="1">S3 Renew Battery</option>
                    <option value="2">S3 Home</option>
                  </select>
                </div>
              </div>

              <div className="relative inline-block">
                <div className="xl:hidden">
                  <button
                    className="p-2 border-2 rounded-md border-gray-400 text-gray-600 dark:bg-gray-600 dark:text-white"
                    onClick={toggleFilter}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </button>
                </div>

                {showFilter && (
                  <div className="flex xl:hidden mt-2 rounded-md absolute z-10 flex-col space-y-2 p-2 shadow-xl bg-white dark:bg-gray-800 border dark:border-gray-600">
                    <div className="w-40">
                      <label
                        htmlFor="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={selectedStatus}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedStatus(selectedValue);
                          setCurrentPage(1);

                          if (selectedValue === "") {
                            clearSearchResults();
                          }
                        }}
                        disabled={deviceLoading || loading}
                      >
                        <option value="">Any</option>
                        <option value="1">Enabled</option>
                        <option value="2">Pending</option>
                        <option value="0">Disabled</option>
                        <option value="3">Deleted</option>
                      </select>
                    </div>

                    <div className="w-40">
                      <label
                        htmlFor="location"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Account
                      </label>
                      <button
                        id="selectAccountDropdown"
                        className="w-40 bg-gray-50 border border-gray-300 text-black dark:text-white dark:bg-gray-700 dark:border-gray-600 focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm rounded-md px-5 py-2.5 inline-flex items-center justify-between"
                        type="button"
                        onClick={toggleSelectLocationDropdown}
                      >
                        {companydropdownLoading ? (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>
                            <span className="truncate">{selectedCompany}</span>
                            <svg
                              className="w-2.5 h-2.5 ml-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </>
                        )}
                      </button>
                      {selectLocationDropdown && (
                        <div
                          id="dropdownSearch"
                          className="z-10 absolute bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                          ref={dropdownRef}
                        >
                          <div className="p-3">
                            <label
                              htmlFor="input-group-search"
                              className="sr-only"
                            >
                              Search
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                  className="w-4 h-4 text-gray-600 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                              </div>
                              <input
                                type="text"
                                id="input-group-search"
                                className="w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Search Account"
                                onChange={(e) =>
                                  setAccountSearchTerm(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <ul
                            className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownSearchButton"
                          >
                            {filteredCompanies.map((company) => (
                              <li key={company.company_id}>
                                <div
                                  className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                                  onClick={() =>
                                    handleCompanyClick(
                                      company.name,
                                      company.company_id
                                    )
                                  }
                                >
                                  <label
                                    htmlFor="checkbox-item-11"
                                    className="w-full py-2 ms-2 t
                              ext-sm font-medium text-black rounded dark:text-white"
                                  >
                                    {company.name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="w-40">
                      <label
                        htmlFor="selectAccountDropdown"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Location
                      </label>
                      <button
                        id="selectAccountDropdown"
                        className="w-40 bg-gray-50 border border-gray-300 text-black dark:text-white dark:bg-gray-700 dark:border-gray-600 focus:ring-1 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm rounded-md px-5 py-2.5 inline-flex items-center justify-between"
                        type="button"
                        onClick={() => {
                          if (!companydropdownLoading) {
                            toggleselectAccountDropdown();
                          }
                        }}
                      >
                        {companydropdownLoading ? (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <>
                            <span className="truncate">{selectedLocation}</span>
                            <svg
                              className="w-2.5 h-2.5 ml-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 4 4 4-4"
                              />
                            </svg>
                          </>
                        )}
                      </button>
                      {selectAccountDropdown && (
                        <div
                          id="dropdownSearch"
                          className="z-10 absolute bg-white rounded-lg shadow w-60 dark:bg-gray-700"
                          ref={dropdownRef}
                        >
                          <div className="p-3">
                            <label
                              htmlFor="input-group-search"
                              className="sr-only"
                            >
                              Search
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg
                                  className="w-4 h-4 text-gray-600 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                  />
                                </svg>
                              </div>
                              <input
                                type="text"
                                id="input-group-search"
                                className="w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Search Location"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                              />
                            </div>
                          </div>

                          {/* Filter and display the locations */}
                          <ul
                            className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
                            aria-labelledby="dropdownSearchButton"
                          >
                            {filterLocationsBySearch().map((location) => (
                              <li key={location.id}>
                                <div
                                  className="flex items-center ps-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                                  onClick={() => {
                                    // Don't update selected company name if it's the Unassigned location
                                    if (location.id !== 100000) {
                                      setSelectedLocation(location.companyName);
                                    }
                                    toggleselectAccountDropdown();
                                    handleLocationSearch(
                                      location.id,
                                      location.name
                                    ); // Handle location search
                                  }}
                                >
                                  <label className="w-full py-2 ms-2 text-sm font-medium text-black rounded dark:text-white">
                                    {location.name}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    <div className="w-40">
                      <label
                        htmlFor="sortByHb"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Sort by HB
                      </label>
                      <select
                        id="sortByHb"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={sortOrder}
                        onChange={handleSortChange}
                        disabled={deviceLoading || loading}
                      >
                        <option value="">All</option>
                        <option value="ASC">Ascending</option>
                        <option value="DESC">Descending</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="deviceType"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Device Type
                      </label>
                      <select
                        id="deviceType"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        onChange={handleDeviceType}
                        disabled={deviceLoading || loading}
                        value={selectedDeviceType}
                      >
                        <option value="all">All Device Types</option>
                        <option value="0">Raspberry Pi</option>
                        <option value="1">S3 Renew Battery</option>
                        <option value="2">S3 Home</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <div className="right">
                <div className="relative">
                  <label
                    htmlFor="small-input"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Serial Number / Device Identifier
                  </label>
                  <div className="relative w-full" ref={searchRef}>
                    <input
                      type="text"
                      id="table-search"
                      className={`pt-2 text-sm mb-4 mr-3 text-gray-900 border border-gray-300 rounded-md min-w-0 bg-gray-50 focus:ring-${color}-500 focus:border-${color}-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                      placeholder="Search"
                      value={searchInput}
                      onChange={handleSearchInputs}
                      disabled={deviceLoading}
                    />
                    {searchResults.length > 0 && (
                      <div className="absolute z-10 w-full bg-white dark:bg-gray-800 text-black dark:text-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
                        {searchResults.map((result, index) => (
                          <div
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                            onClick={() =>
                              handleResultClick(
                                result.page,
                                result.serial_number,
                                result.device_identifier
                              )
                            }
                          >
                            <h1>
                              <span className="font-semibold">
                                Serial Number:{" "}
                              </span>
                              {result.serial_number}
                            </h1>
                            {result.device_identifier && (
                              <h1>
                                <span className="font-semibold">
                                  Device Identifier:{" "}
                                </span>
                                {result.device_identifier}
                              </h1>
                            )}
                            <h1 className="float-right text-gray-500 dark:text-gray-200">
                              <span className="font-semibold">Page</span>{" "}
                              {result.page}
                            </h1>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-between mb-1">
              <div className="flex items-center">
                <label
                  htmlFor="itemsPerPage"
                  className="mr-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Show
                </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value));
                    setCurrentPage(1); // Reset to first page when changing items per page
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                  <option value={125}>125</option>
                  <option value={150}>150</option>
                  <option value={175}>175</option>
                  <option value={200}>200</option>
                </select>
                <span className="ml-2 text-sm text-gray-700 dark:text-gray-400">
                  entries
                </span>
              </div>

              <div className="flex items-center">
                <button
                  className={`flex py-1.5 px-2 items-center text-sm rounded-md border-2 border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500`}
                  onClick={clearSearchResults}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  <h1>Clear Search Result</h1>
                </button>
                <button
                  className={`flex py-1.5 px-2 items-center text-sm rounded-md border-2 border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500`}
                  onClick={handleDownloadDevice}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-4 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <h1>Download</h1>
                </button>{" "}
                {is_read_only !== 1 && (
                  <button
                    className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
                    style={{
                      backgroundColor: `${backgroundColor}`,
                      color: `${textColor}`,
                    }}
                    onClick={toggleAddDevice}
                  >
                    + Add Device
                  </button>
                )}
              </div>
            </div>
            <div className="relative overflow-x-auto">
              <table className="border border-black dark:border-gray-300 animate__animated animate__fadeIn rounded-t-md w-full text-sm text-left rtl:text-right cursor-pointer font-inter leading-4">
                <thead
                  className={`border border-black dark:border-white text-sm text-${color}-800 rounded-t-md dark:text-${color}-800 uppercase bg-${color}-200 dark:bg-${color}-200 font-inter leading-4`}
                  style={{
                    backgroundColor: `${tableHeaderColor}`,
                    color: `${tableHeaderTextColor}`,
                  }}
                >
                  <tr className="border border-black">
                    <th scope="col" className="px-6 py-3 border border-black">
                      <div>
                        <p>Device Id</p>
                        <p> Serial Number</p>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      <div>
                        <p>Location</p>
                        <p>LDID</p>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      <p>Last HB</p>
                      <p>Last Schedule</p>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      <div>
                        <p>Type &</p>
                        <p>Model</p>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      <div>
                        <p>Version</p>
                        <p>Phone</p>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 border border-black">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm font-inter leading-4">
                  {loading ? (
                    <tr>
                      <td colSpan="7" className="h-64">
                        <div
                          role="status"
                          className="flex justify-center items-center h-full"
                        >
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : currentItems.length > 0 ? (
                    sortDevices([...currentItems])
                      .filter((device) => {
                        if (!selectedSerialNumber) return true;
                        return device.serial_number === selectedSerialNumber;
                      })
                      .filter(
                        (device) =>
                          selectedDeviceType === "all" ||
                          device.type.toString() === selectedDeviceType
                      )
                      .map((device) => {
                        const matchingDeviceData = deviceData.find(
                          (item) => item.deviceId === device.device_id
                        );

                        return (
                          <tr
                            key={device.device_id}
                            className="odd:bg-gray-100 odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                          >
                            <td className="border border-black dark:border-gray-300 px-6 w-32">
                              <div>
                                <Link to={`/devices/${device.device_id}`}>
                                  <h1
                                    className="mb-1 hover:underline text-black dark:text-white"
                                    onClick={() =>
                                      handleSerialNumberClick(device.device_id)
                                    }
                                  >
                                    <p className="flex items-center text-black dark:text-white">
                                      <div>
                                        <svg
                                          fill="#7a7a7a"
                                          width="25px"
                                          height="25px"
                                          viewBox="-4 0 32 32"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="text-black dark:text-white"
                                        >
                                          <title>barcode</title>
                                          <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                                        </svg>
                                      </div>
                                      <p className="font-medium">
                                        {device.serial_number}
                                      </p>
                                    </p>
                                  </h1>
                                </Link>
                                <ul className="space-y-2 font-medium border-t border-gray-400 dark:border-gray-700" />

                                <p className="text-black dark:text-white">
                                  {device.device_identifier}
                                </p>
                                <h1>
                                  {" "}
                                  <p
                                    className={`p-1 mt-2 text-xs w-fit rounded-sm 
                                 ${
                                   device.is_test_account === 1 ? "" : "hidden"
                                 }`}
                                    style={{
                                      backgroundColor: `#337ab7`,
                                      color: `white`,
                                      fontWeight: `bold`,
                                    }}
                                  >
                                    Test Account
                                  </p>
                                </h1>
                              </div>
                            </td>
                            <td className="border border-black dark:border-gray-300 w-64 px-6 py-2 text-black dark:text-white ">
                              {deviceLoading ? (
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <div className="flex justify-between items-center gap-6">
                                  <div className="flex flex-col space-y-1">
                                    <div>
                                      <Link
                                        to={`/location/${
                                          device.location_id || ""
                                        }`}
                                      >
                                        <span
                                          className="hover:underline font-medium"
                                          onClick={() =>
                                            handleLocationClick(
                                              device.device_id
                                            )
                                          }
                                        >
                                          {device.location_name}
                                        </span>
                                      </Link>
                                    </div>
                                    <div>{device.location_device_id}</div>
                                  </div>
                                  <div className="gap-1 flex flex-col items-end">
                                    <div
                                      className={`py-0.5 px-1 w-fit rounded-[5px] text-[10px] font-semibold text-white bg-[#3d3d3d] whitespace-nowrap ${
                                        device.location_device_name == null
                                          ? "hidden"
                                          : ""
                                      }`}
                                    >
                                      {device.location_device_name}
                                    </div>
                                    {device.timer_type === 1 && (
                                      <div
                                        className="py-0.5 px-1 w-fit rounded-[5px] text-[10px] font-semibold text-white bg-[#FFB606] whitespace-nowrap"
                                        style={{ backgroundColor: "#FFB606" }}
                                      >
                                        Indexing Value
                                      </div>
                                    )}

                                    {device.timer_type === 0 && (
                                      <div
                                        className="py-0.5 px-1 w-fit rounded-[5px] text-[10px] font-semibold text-white bg-[#FFB606] whitespace-nowrap"
                                        style={{ backgroundColor: "#FFB606" }}
                                      >
                                        Digital Timer
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="border border-black dark:border-gray-300 px-6 py-2">
                              <div className="flex justify-center items-center flex-col gap-2">
                                <div
                                  className={`flex items-center w-fit px-1.5 py-0.5 justify-center text-white font-medium rounded-lg text-xs text-center whitespace-nowrap`}
                                  style={getBackgroundColorStyle(
                                    device.last_hb
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-3 h-3 mr-0.5"
                                  >
                                    <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                                  </svg>
                                  <p>{formatDate(device.last_hb)}</p>
                                </div>
                                <div
                                  className={`flex items-center w-fit px-1.5 py-0.5 justify-center text-white font-medium rounded-lg text-xs text-center whitespace-nowrap`}
                                  style={getBackgroundColorStyle(
                                    device.last_schedule
                                  )}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-4"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                  <p>{formatDate(device.last_schedule)}</p>
                                </div>
                              </div>
                            </td>

                            <td className="border border-black dark:border-gray-300 px-6 text-black dark:text-white">
                              <div>
                                <p className="mb-1">
                                  {DEVICE_TYPES[device.type]}
                                </p>
                                <ul className="space-y-2 font-medium border-t text-sm border-gray-400 dark:border-gray-700" />
                                <p className="mb-1">{device.model}</p>
                              </div>
                            </td>
                            <td className="border border-black dark:border-gray-300 px-6 py-4 text-xs text-black dark:text-white leading-4 text-center">
                              <div className="flex flex-col items-center justify-center space-y-2">
                                <p
                                  className={`flex p-1 w-fit items-center justify-center whitespace-nowrap focus:outline-none focus:ring-2 font-medium rounded-md text-xs text-center ${
                                    getFirmwareVersion(
                                      device.firmware_package_id
                                    ) !== device.last_firmware
                                      ? "text-yellow-900 bg-yellow-300 focus:ring-yellow-300"
                                      : "text-white focus:ring-green-300"
                                  }`}
                                  style={
                                    getFirmwareVersion(
                                      device.firmware_package_id
                                    ) === device.last_firmware
                                      ? { backgroundColor: "#62cb30" }
                                      : {}
                                  }
                                >
                                  {getFirmwareVersion(
                                    device.firmware_package_id
                                  ) !== device.last_firmware
                                    ? `${getFirmwareVersion(
                                        device.firmware_package_id
                                      )} / ${device.last_firmware ?? "?"}`
                                    : getFirmwareVersion(
                                        device.firmware_package_id
                                      )}
                                </p>
                                <p>{device.last_phone}</p>
                              </div>
                            </td>

                            <td className="border border-black dark:border-gray-300 px-6 py-4 text-xs flex-col font-semibold text-white leading-4 text-center">
                              <div className="flex justify-center">
                                <div
                                  className={`status p-1 rounded text-xs ${
                                    device.status === 0
                                      ? "bg-[#d9534f] text-white"
                                      : device.status === 2
                                      ? "bg-blue-300 text-blue-900"
                                      : device.status === 3
                                      ? "bg-[#d9534f] text-white"
                                      : device.status === 1
                                      ? "bg-[#62cb30] text-white"
                                      : ""
                                  }`}
                                >
                                  {device.status === 0
                                    ? "Disabled"
                                    : device.status === 1
                                    ? "Active"
                                    : device.status === 2
                                    ? "Pending"
                                    : device.status === 3
                                    ? "Deleted"
                                    : ""}
                                </div>
                              </div>
                            </td>

                            <td className="border border-black dark:border-gray-300 px-6 py-4">
                              <div className="flex justify-center items-center">
                                {is_read_only !== 1 && (
                                  <button
                                    className="edit p-1 hover:bg-gray-200 rounded-full"
                                    onClick={() =>
                                      toggleEditDevice(device.device_id)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="blue"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                      />
                                    </svg>
                                  </button>
                                )}

                                {is_read_only !== 1 &&
                                  selectedStatus !== "3" && (
                                    <button
                                      className="delete p-1 hover:bg-gray-200 rounded-full"
                                      onClick={() =>
                                        toggleDeleteDevice(
                                          device.device_id,
                                          device.device_identifier
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="red"
                                        className="size-6"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  )}

                                {is_read_only !== 1 &&
                                  selectedStatus == "3" && (
                                    <button
                                      className="delete p-1 hover:bg-gray-200 rounded-full"
                                      onClick={() =>
                                        toggleRestoreDevice(
                                          device.device_id,
                                          device.device_identifier
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="size-6"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                        />
                                      </svg>
                                    </button>
                                  )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="8" x2="12" y2="12"></line>
                          <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>

                        <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                          No Device Found
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="flex justify-between">
              <div className="tableDataPagination">
                <span className="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {indexOfFirstItem + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {Math.min(indexOfLastItem, getDeviceData.length)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {getDeviceData.length}
                  </span>{" "}
                  Entries
                </span>
              </div>
              <div className="overflow-x-auto">
                <div className="pagination flex flex-row mt-2 mb-2">
                  <button
                    onClick={prevBatch}
                    disabled={currentBatch === 1}
                    className="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    &lt;&lt;
                  </button>
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <button
                        key={index}
                        disabled={deviceLoading}
                        onClick={() => goToPage(startPage + index)}
                        className={`flex items-center justify-center px-2 h-8 leading-tight border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                        style={
                          currentPage === index + 1
                            ? {
                                backgroundColor: backgroundColor,
                                color: textColor,
                              }
                            : {}
                        }
                      >
                        {startPage + index}
                      </button>
                    )
                  )}
                  <button
                    onClick={nextBatch}
                    disabled={currentBatch === totalBatches}
                    className="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>

            <div className="spacer p-5"></div>
          </div>
        </div>
        {addDevice && (
          <AddDevice
            addDevice={addDevice}
            setAddDevice={setAddDevice}
            firmwarePackage={firmwarePackage}
            firmwareVersion={firmwareVersion}
            fetchGetDeviceData={fetchGetDeviceData}
          />
        )}

        {editDevice && (
          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
            style={{
              // backdropFilter: "blur(5px)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="relative p-4 w-full max-w-5xl h-full md:h-auto">
              {/* <!-- Modal content --> */}
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                {/* <!-- Modal header --> */}
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Device
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={closeEditModal}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <form onSubmit={handleEditDeviceSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-3">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Serial Number
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={deviceIdDetails.serial_number}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            serial_number: e.target.value,
                          }));
                        }}
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="firmwarePackage"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Firmware Package
                      </label>
                      <select
                        id="firmwarePackage"
                        value={deviceIdDetails.firmware_package_id}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            firmware_package_id: e.target.value,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option defaultValue>Select a Firmware Package</option>
                        {Object.entries(firmwarePackage).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="friendlyDeviceName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Friendly Device Name
                      </label>
                      <input
                        type="text"
                        name="friendlyDeviceName"
                        value={deviceIdDetails.device_identifier}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            device_identifier: e.target.value,
                          }));
                        }}
                        id="friendlyDeviceName"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="countries"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        OTA Enabled
                      </label>
                      <select
                        id="countries"
                        value={deviceIdDetails.ota_enabled} // Bind the value to the state
                        onChange={(e) => {
                          const newValue = e.target.value; // Get the selected value
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            ota_enabled: newValue,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="deviceType"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Device Type
                      </label>
                      <select
                        id="countries"
                        value={deviceIdDetails.type} // Bind the value to the state
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            type: e.target.value,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="0">Raspberry Pi</option>
                        <option value="1">S3 Renew Battery</option>
                        <option value="2">S3 Home</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="otaLastRequest"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        OTA Last Request
                      </label>
                      <input
                        type="text"
                        name="otaLastRequest"
                        value={deviceIdDetails.ota_last_request}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            ota_last_request: e.target.value,
                          }));
                        }}
                        id="otaLastRequest"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="deviceModel"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Device Model
                      </label>
                      <input
                        type="text"
                        name="deviceModel"
                        value={deviceIdDetails.model}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            model: e.target.value, // Update the state with the new input value
                          }));
                        }}
                        id="deviceModel"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="countries"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        OLD Firmware (deprecated)
                      </label>
                      <select
                        id="countries"
                        value={deviceIdDetails.firmware}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            firmware: e.target.value,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option defaultValue>Select Version</option>
                        {Object.entries(firmwareVersion).map(
                          ([category, versions]) => (
                            <optgroup key={category} label={category}>
                              {Object.entries(versions).map(
                                ([version, label]) => (
                                  <option
                                    key={`${category}-${version}`}
                                    value={version}
                                  >
                                    {label}
                                  </option>
                                )
                              )}
                            </optgroup>
                          )
                        )}
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="status"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        value={deviceIdDetails.status}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            status: e.target.value,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="2">Pending</option>
                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>
                        <option value="3">Deleted</option>
                      </select>
                    </div>
                    <div>
                      <label
                        htmlFor="deviceSimNumber"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Device Sim Number
                      </label>
                      <input
                        type="text"
                        name="deviceSimNumber"
                        value={deviceIdDetails.sim_phone_number}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            sim_phone_number: e.target.value,
                          }));
                        }}
                        id="deviceSimNumber"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="modemNumber"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Modem Number
                      </label>
                      <input
                        type="text"
                        value={deviceIdDetails.modem_number}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            modem_number: e.target.value, // Update the state with the new input value
                          }));
                        }}
                        name="modemNumber"
                        id="modemNumber"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="countries"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Sim Carrier{" "}
                      </label>
                      <select
                        id="countries"
                        value={deviceIdDetails.sim_carrier}
                        onChange={(e) => {
                          setDeviceIdDetails((prevDetails) => ({
                            ...prevDetails,
                            sim_carrier: e.target.value,
                          }));
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="" disabled>
                          Select Carrier Type
                        </option>
                        <option value="0">Verizon</option>
                        <option value="1">T-Mobile</option>
                        <option value="2">Sprint</option>
                        <option value="3">AT & T</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your message
                    </label>
                    <textarea
                      id="message"
                      rows="4"
                      value={deviceIdDetails.notes}
                      onChange={(e) => {
                        setDeviceIdDetails((prevDetails) => ({
                          ...prevDetails,
                          notes: e.target.value,
                        }));
                      }}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write your thoughts here..."
                    ></textarea>
                  </div>

                  <div className="flex items-center justify-end mt-2">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={closeEditModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </AsideLayout>
  );
}
