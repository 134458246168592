import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { ThemeContext } from "../../components/context/themeContext/ThemeContext";
import AddAccounts from "../../components/modals/AddAccounts";
import EditAccounts from "../../components/modals/EditAccounts";
import AsideLayout from "./AsideLayout/AsideLayout";
import { useSelectedCompany } from "../../components/context/apiContext/SelectedCompanyContext";
import { useSelector } from "react-redux";
import ApiLink from "../../components/api/ApiLink";

export default function Accounts() {
  const history = useHistory();
  const swal = require("sweetalert2");

  const { sideBarOpen } = useContext(ThemeContext);

  const {
    updateSelectedCompanyName,
    updateSelectedLocation,
    updateCompanyData,
    companyData,
  } = useSelectedCompany();

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const [isCompanyListFetched, setIsCompanyListFetched] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false); //loading screen for table accounts
  const {
    color,
    textColor,
    backgroundColor,
    tableHeaderColor,
    tableHeaderTextColor,
  } = useContext(ThemeContext);
  const [companyIds, setCompanyIds] = useState([]);
  const [companyLocationData, setCompanyLocationData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("1");

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const userType = useSelector((state) => state.auth.userType);

  const [menuDropdown, setMenuDropDown] = useState(false);
  // const is_read_only = useState(0);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const authState = useSelector((state) => state.auth);

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortByStatus, setSortByStatus] = useState("1");

  const fetchCompanyList = useCallback(
    async (status = sortByStatus) => {
      setLoading(true);
      try {
        const requestBody = {
          email,
          password,
          status: sortByStatus,
        };

        const response = await ApiLink.post("/api/company-list", requestBody);
        console.log("response: ", response);
        if (response.status === 200) {
          const data = response.data.Data.map((company) => ({
            ...company,
            company_location_id: JSON.parse(
              company.company_location_id || "[]"
            ),
          }));

          updateCompanyData(data);
        } else {
          console.error("Error:", response.data);
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      } finally {
        setLoading(false);
      }
    },
    [email, password, sortByStatus]
  );

  const memoizedCompanyData = useMemo(() => {
    return companyData;
  }, [companyData]);

  useEffect(() => {
    if (!memoizedCompanyData || memoizedCompanyData.length === 0) {
      const controller = new AbortController();
      const signal = controller.signal;

      fetchCompanyList().catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
        }
      });

      return () => controller.abort();
    }
  }, [sortByStatus, fetchCompanyList, memoizedCompanyData]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      fetchCompanyList();
    }, 300);

    return () => clearTimeout(debounce);
  }, [sortByStatus]);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    setCurrentPage(1); // Reset to first page when status changes

    fetchCompanyList(newStatus);
  };

  const getLocationData = (companyId) => {
    return companyLocationData.filter(
      (location) => location.company_id === companyId
    );
  };

  const handleLocationClick = (locationId, locationName) => {
    console.log("locationId: ", locationId);
    console.log("locationName: ", locationName);
    history.push(`/location/${locationId}`);

    const fetchCompanyLocationID = async () => {
      try {
        const response = await ApiLink.post("/api/company-locations-id", {
          email,
          password,
          company_location_id: locationId,
        });

        if (response.status === 200) {
          const { Company_location } = response.data.Data;
          const {
            company_id,
            city,
            state,
            zip,
            address_line1: address,
          } = Company_location;

          updateSelectedLocation({
            locationId,
            locationName,
            companyId: company_id,
            city,
            state,
            zip,
            address,
          });
        }
      } catch (error) {
        console.error("Error: accounts fetching location id", error);
      }
    };

    fetchCompanyLocationID();
  };

  const handleCompanyNameClick = (
    companyName,
    company_id,
    city,
    state,
    zip,
    type
  ) => {
    updateSelectedCompanyName({
      companyName,
      company_id,
      city,
      state,
      zip,
      type,
    });
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const getColorCode = (color) => {
    switch (color) {
      case "green":
        return "#057a55";
      case "blue":
        return "#1c64f2";

      case "purple":
        return "#7e3af2";
      case "red":
        return "#FF0000";
      case "yellow":
        return "#9F580A";
      case "pink":
        return "#D61F69";
      default:
        return "#057a55";
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
      setCurrentPage(1);
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const getclassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return;
    }
    return sortConfig.direction === "ascending" ? "arrow-up" : "arrow-down";
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
      setCurrentPage(1);
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const filteredData = useMemo(() => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return companyData.filter((company) => {
      if (company.name.toLowerCase().includes(lowerSearchTerm)) {
        return true;
      }
      for (let i = 0; i < company.company_location_id.length; i++) {
        if (
          company.company_location_id[i].name
            .toLowerCase()
            .includes(lowerSearchTerm)
        ) {
          return true;
        }
      }
      return false;
    });
  }, [companyData, searchTerm]);
  const COMPANY_TYPES = {
    1: "City",
    2: "Commercial Building",
    3: "Community",
    4: "Construction",
    5: "County",
    6: "Gold Course",
    7: "Park",
    8: "Portfolio Admin",
    9: "Homeowner",
    0: "INFO_NOT_AVAILABLE",
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...filteredData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "address") {
          const aAddress = `${a.city} ${a.state} ${a.zip}`;
          const bAddress = `${b.city} ${b.state} ${b.zip}`;
          if (aAddress < bAddress) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aAddress > bAddress) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else if (sortConfig.key === "type") {
          const aType = COMPANY_TYPES[a.type] || "";
          const bType = COMPANY_TYPES[b.type] || "";
          if (aType < bType) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aType > bType) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else if (sortConfig.key === "locations") {
          const aLocations = a.company_location_id.length;
          const bLocations = b.company_location_id.length;
          if (aLocations < bLocations) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aLocations > bLocations) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredData, sortConfig]);

  const paginatedData = sortedData.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  const handleSortByDateAdded = () => {
    let direction = "ascending";

    if (
      sortConfig.key === "date_added" &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
      setCurrentPage(1);
    }

    setSortConfig({ key: "date_added", direction });
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    setTotalPages(Math.ceil(sortedData.length / ITEMS_PER_PAGE));
  }, [sortedData]);

  const COMPANY_STATUS = {
    1: "Active",
    2: "Pending",
    3: "Deleted",
    0: "Disabled",
  };

  const toggleMenuDropdown = () => {
    setMenuDropDown(!menuDropdown);
  };

  const addressSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
      setCurrentPage(1);
    }
    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleDeleteUser = (name, company_id) => {
    swal
      .fire({
        title: "Do you want to delete this company account?",
        text: `"${name}" will be deleted permanently.`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/delete-company", {
              email: email,
              password: password,
              company_id: company_id,
            });

            if (response.status === 200) {
              console.log(response.data);
              swal.fire({
                title: "Company Account Deleted Successfully",
                icon: "success",
                toast: true,
                timer: 4000,
                position: "top-right",
                timerProgressBar: true,
                showConfirmButton: false,
              });
              fetchCompanyList();
              if (response.data.Data.is_valid == false) {
                swal.fire({
                  title: "Error Occured. Please try again.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid == true) {
                swal.fire({
                  title: "Company Account Deleted Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyList();
              }
            }
          } catch (error) {
            swal.fire({
              title: "Error Occured. Please try again.",
              icon: "Error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  const handleRestoreCompany = (name, company_id) => {
    swal
      .fire({
        title: "Do you want to restore this company account?",
        text: `Company "${name}" account will be restored.`,
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#FF0000",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiLink.post("/api/restore-company", {
              email: email,
              password: password,
              company_id: company_id,
            });

            if (response.status === 200) {
              console.log(response.data);
              if (response.data.Data.is_valid == false) {
                swal.fire({
                  title: "Error Occured. Please try again.",
                  icon: "warning",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
              } else if (response.data.Data.is_valid == true) {
                swal.fire({
                  title: "Company Account Restored Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
                fetchCompanyList();
              }
            }
          } catch (error) {
            swal.fire({
              title: "Error Occured. Please try again.",
              icon: "Error",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      });
  };

  return (
    <AsideLayout>
      <div
        className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 `}
        style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
      >
        <div className="p-4 mt-20">
          <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
            <div className="relative mt-1">
              <h1
                style={{ fontSize: 20 }}
                className="text-black font-bold dark:text-gray-200 "
              >
                Sustainable Sprinklers Accounts
              </h1>
              <div className="mb-2"></div>
              <div className="flex justify-between">
                <div>
                  <div className="relative">
                    <svg
                      className="absolute w-4 h-4 text-gray-500 dark:text-gray-400 mb-9 top-3 left-2 pointer-events-none"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                    <input
                      type="text"
                      id="table-search"
                      className={`pt-2 pl-9 pr-3 text-sm mb-1 text-gray-900 border border-gray-300 rounded-md min-w-0 bg-gray-50 focus:ring-${color}-500 focus:border-${color}-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>

                  <div className="flex items-center gap-1 text-xs text-black dark:text-white">
                    <h1>Showing</h1>
                    <select
                      id="showEntry"
                      value={ITEMS_PER_PAGE}
                      onChange={(e) => {
                        setITEMS_PER_PAGE(parseInt(e.target.value, 10));
                        setCurrentPage(1);
                      }}
                      className={`bg-gray-50 border border-gray-300 w-min text-gray-900 text-xs rounded-lg focus:ring-${color}-500 focus:border-${color}-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-${color}-500 dark:focus:border-${color}-500`}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                      <option value={125}>125</option>
                      <option value={150}>150</option>
                      <option value={175}>175</option>
                      <option value={200}>200</option>
                    </select>
                    <h1>entries</h1>
                  </div>
                </div>
                <div className="flex gap-2 items-center mb-1">
                  <div>
                    <div className="hidden xl:flex items-center space-x-4">
                      <div className="">
                        <select
                          id="sortByStatus"
                          onChange={(e) => setSortByStatus(e.target.value)}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="1">Enabled</option>
                          <option value="3">Deleted</option>
                        </select>
                      </div>

                      <div className="flex items-center p-1 border-2 rounded-md border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                        <button className="text-sm">Download</button>
                      </div>

                      <div
                        className="flex items-center p-1 border-2 rounded-md border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                        onClick={() => fetchCompanyList()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                        <button className="text-sm">Reload Data</button>
                      </div>
                    </div>

                    {/* Dropdown Menu for smaller screens */}
                    <div className="xl:hidden">
                      <button
                        className="p-2 border-2 rounded-md border-gray-400 text-gray-600 dark:bg-gray-600 dark:text-white"
                        onClick={toggleMenuDropdown}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                          />
                        </svg>
                      </button>

                      {/* Dropdown items */}
                      {menuDropdown && (
                        <div className="flex absolute right-4 z-10 flex-col mt-2 space-y-2 p-2 shadow-xl bg-white dark:bg-gray-800 border dark:border-gray-600">
                          <div className="">
                            <select
                              id="sortByStatus"
                              onChange={(e) => setSortByStatus(e.target.value)}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                              <option value="1">Enabled</option>
                              <option value="3">Deleted</option>
                            </select>
                          </div>

                          <div className="flex items-center p-1 border-2 rounded-md border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                              />
                            </svg>
                            <button className="text-sm">Download</button>
                          </div>

                          <div
                            className="flex items-center p-1 border-2 rounded-md border-gray-400 mr-2 gap-1 hover:bg-gray-400 text-gray-600 hover:text-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                            onClick={() => fetchCompanyList()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                            </svg>
                            <button className="text-sm">Reload Data</button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    {is_read_only !== 1 && (
                      <AddAccounts fetchCompanyList={fetchCompanyList} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-x-auto ">
              {loading ? ( // Display spinner 1.5secs
                <div role="status" className="flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <table className="animate__animated animate__fadeIn w-full mt-2 text-sm text-left cursor-pointer">
                  <thead
                    className={`border border-black dark:border-white uppercase font-inter leading-4`}
                    style={{
                      backgroundColor: `${tableHeaderColor}`,
                      color: `${tableHeaderTextColor}`,
                    }}
                  >
                    <tr className="">
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black"
                        onClick={() => requestSort("name")}
                      >
                        <div className="flex flex-row justify-between items-center">
                          <h1 className="flex">
                            Company Name{" "}
                            <span className={getclassNamesFor("name")}></span>{" "}
                          </h1>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black"
                        onClick={() => handleSort("locations")}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <h1 className="flex ">Locations </h1>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black"
                        onClick={() => addressSort("address")}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <h1 className="">Address/Phone</h1>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black"
                        onClick={() => handleSort("type")}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <h1 className="">Company Type</h1>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 border border-black">
                        <div
                          className="flex flex-row items-center justify-between"
                          onClick={handleSortByDateAdded}
                        >
                          <h1 className="">Date Added</h1>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                            />
                          </svg>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 border border-black justify-between"
                        onClick={() => handleSort("status")}
                      >
                        <div className="flex flex-row ">
                          <h1 className="">Status</h1>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 border border-black">
                        <h1 className="">Actions</h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((company) => (
                        <tr
                          key={company.company_id}
                          className="odd:bg-white odd:dark:bg-gray-900 even:bg-purple-200 even:dark:bg-gray-800 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <td
                            className={`border border-black dark:border-gray-300 text-sm px-6 py-4 text-black dark:text-white hover:text-blue-600 dark:hover:text-blue-600 leading-4`}
                          >
                            <Link
                              to={`/accounts/${company.company_id}`}
                              onClick={() => {
                                if (company.type === 8) {
                                  sessionStorage.setItem(
                                    "activeItem",
                                    "companyType8"
                                  );
                                } else {
                                  sessionStorage.setItem(
                                    "activeItem",
                                    "dashboard"
                                  );
                                }
                              }}
                            >
                              <div
                                onClick={() =>
                                  handleCompanyNameClick(
                                    company.name,
                                    company.company_id,
                                    company.city,
                                    company.state,
                                    company.zip,
                                    company.type
                                  )
                                }
                              >
                                {company.name}
                              </div>
                            </Link>

                            <h1
                              className={`p-1 mt-2 text-xs w-fit rounded-sm 
                                 ${
                                   company.is_test_account == 1 ? "" : "hidden"
                                 }`}
                              style={{
                                backgroundColor: `#337ab7`,
                                color: `white`,
                                fontWeight: `bold`,
                              }}
                            >
                              Test Account
                            </h1>
                          </td>

                          <td className="border border-black dark:border-gray-300 px-2 py-4 text-sm flex-col text-black dark:text-white leading-4">
                            {company.company_location_id.length > 0 ? (
                              company.company_location_id.map((location) => (
                                <div
                                  key={location.id}
                                  onClick={() =>
                                    handleLocationClick(
                                      location.id,
                                      location.name
                                    )
                                  }
                                  className="cursor-pointer hover:underline"
                                >
                                  <h1
                                    className={`flex p-1 mt-2 min-w-[80px] w-full sm:w-auto sm:min-w-[100px] md:min-w-[150px] lg:min-w-[200px] rounded-sm break-words`}
                                  >
                                    {location.name}
                                  </h1>
                                </div>
                              ))
                            ) : (
                              <div>-</div>
                            )}
                          </td>

                          <td className="border border-black dark:border-gray-300 text-sm px-6 py-4 flex-col text-black dark:text-white leading-4">
                            <div>
                              {company.city} {company.state} {company.zip}
                            </div>
                            <div>P: {company.phone}</div>
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4 text-sm flex-col text-black dark:text-white leading-4">
                            {COMPANY_TYPES[company.type]}
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4 text-sm text-black dark:text-white leading-4 whitespace-nowrap">
                            {company.date_added.slice(0, 10)}
                          </td>
                          <td
                            className={`border border-black dark:border-gray-300 px-6 py-4 text-xs flex-col font-semibold text-black dark:text-white leading-4 text-center`}
                          >
                            <div
                              className={`status px-2 py-1 text-xs rounded ${
                                company.status === 1
                                  ? "text-white"
                                  : company.status === 2
                                  ? "bg-blue-300 text-blue-900"
                                  : company.status === 3 || company.status === 0
                                  ? "bg-red-300 text-red-900"
                                  : ""
                              }`}
                              style={
                                company.status === 1
                                  ? { backgroundColor: "#62cb31" }
                                  : {}
                              }
                            >
                              {COMPANY_STATUS[company.status]}
                            </div>
                          </td>
                          <td className="border border-black dark:border-gray-300 px-6 py-4 leading-4">
                            <div className="flex gap-3 items-center">
                              {is_read_only !== 1 && (
                                <div className="p-1 hover:bg-gray-200 dark:hover:bg-gray-200 rounded-full">
                                  <EditAccounts
                                    companyname={company.name}
                                    companystatus={company.status}
                                    companyphone={company.phone}
                                    companycity={company.city}
                                    companyaddress={company.address_line1}
                                    companytype={company.type}
                                    companyZip={company.zip}
                                    companyState={company.state}
                                    companyaddress2={company.address_line2}
                                    website={company.website}
                                    is_test_account={company.is_test_account}
                                    company_id={company.company_id}
                                    fetchCompanyList={fetchCompanyList}
                                  />
                                </div>
                              )}
                              {is_read_only !== 1 && sortByStatus !== "3" && (
                                <div
                                  className="p-1 hover:bg-gray-200 dark:hover:bg-gray-200 rounded-full"
                                  onClick={() =>
                                    handleDeleteUser(
                                      company.name,
                                      company.company_id
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="red"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </div>
                              )}
                              {is_read_only !== 1 && sortByStatus == "3" && (
                                <div
                                  className="p-1 hover:bg-gray-200 dark:hover:bg-gray-200 rounded-full"
                                  onClick={() =>
                                    handleRestoreCompany(
                                      company.name,
                                      company.company_id
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="size-6"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>

                          <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                            No account found
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
              <div className="tableDataPagination">
                <span className="text-sm text-gray-700 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {startIndex + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {Math.min(startIndex + ITEMS_PER_PAGE, sortedData.length)}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {sortedData.length}
                  </span>{" "}
                  Entries
                </span>
              </div>

              <div className="max-w-full overflow-auto">
                <div className="pagination flex flex-wrap justify-center sm:justify-end gap-1">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="flex items-center justify-center px-2 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Previous
                  </button>
                  <div className="flex flex-wrap justify-center gap-1">
                    {Array.from({
                      length: Math.ceil(sortedData.length / ITEMS_PER_PAGE),
                    }).map((_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageClick(index + 1)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white 
                         
                        `}
                        style={
                          currentPage === index + 1
                            ? {
                                backgroundColor: backgroundColor,
                                color: textColor,
                              }
                            : {}
                        }
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage >= totalPages}
                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="spacer p-5"></div>
          </div>
        </div>
      </div>
    </AsideLayout>
  );
}
