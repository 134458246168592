import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../components/context/themeContext/ThemeContext";
import AddViewUsers from "../AcountInfo/modals/ViewUsers.js/AddViewUsers";
import { useSelector } from "react-redux";
import ApiLink from "../../../components/api/ApiLink";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function ViewUsers() {
  const { companyName } = useParams();
  const { sideBarOpen, tableHeaderTextColor, tableHeaderColor } =
    useContext(ThemeContext);

  // State
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [editUsers, setEditUsers] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const [locationListData, setLocationListData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [noUserMessage, setNoUserMessage] = useState("");
  const [resetPassDetails, setResetPassDetails] = useState({
    resetPassEmail: null,
    resetPassfirstName: null,
    resetPasslastName: null,
    resetPassUserID: null,
    resetPassVerifyCode: null,
  });

  // Redux selectors
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const is_read_only = useSelector((state) => state.auth.is_read_only);
  const userType = useSelector((state) => state.auth.userType);
  const companyId = useSelector((state) => state.auth.company_id);

  const [selectedCompanyName, setSelectedCompanyName] = useState({
    companyName: null,
    company_id: null,
    city: null,
    state: null,
    zip: null,
    type: null,
  });

  const [getHandleIconData, setGetHandleIconData] = useState({
    usersid: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    usertype: null,
    system_notifications: null,
    is_read_only: null,
    alerts_access: null,
    alerts: null,
    company_location_id: null,
    company_id: null,
  });

  // Constants
  const USER_TYPES = {
    1: "Super Admin",
    2: "Account Admin",
    4: "Account User",
  };

  // Fetch company data on mount
  useEffect(() => {
    const fetchCompanyList = async () => {
      if (userType !== 1) {
        setSelectedCompanyName({
          ...selectedCompanyName,
          company_id: companyId,
        });
        return;
      }

      try {
        const response = await ApiLink.post("/api/company-list", {
          email,
          password,
        });

        if (response.status === 200) {
          const matchingCompany = response.data.Data.find(
            (company) => String(company.company_id) === String(companyName)
          );

          if (matchingCompany) {
            setSelectedCompanyName({
              companyName: matchingCompany.name,
              company_id: matchingCompany.company_id,
              city: matchingCompany.city,
              state: matchingCompany.state,
              zip: matchingCompany.zip,
              type: matchingCompany.type,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching company list:", error);
      }
    };

    fetchCompanyList();
  }, [companyName, email, password]);

  // Fetch users when company is selected
  useEffect(() => {
    if (selectedCompanyName.company_id) {
      fetchUserList();
    }
  }, [email, password, selectedCompanyName.company_id, selectedStatus]);

  const fetchUserList = async () => {
    setLoading(true);
    try {
      const response = await ApiLink.post("/api/user-list", {
        email,
        password,
        company_id: selectedCompanyName.company_id,
        status: selectedStatus,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data.Data)) {
          setUserListData(response.data.Data);
        } else if (response.data.Data.Message) {
          setNoUserMessage(response.data.Data.Message);
          setUserListData([]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Checkbox handling
  const renderCheckboxes = (checkedLocationIds) => {
    let validCheckedLocationIds = [];
    try {
      if (typeof checkedLocationIds === "string") {
        validCheckedLocationIds = JSON.parse(checkedLocationIds);
      } else if (Array.isArray(checkedLocationIds)) {
        validCheckedLocationIds = checkedLocationIds;
      }
    } catch (e) {
      console.error("Error parsing checkedLocationIds:", e);
    }

    return locationListData.map((location) => {
      const locationIdStr = location.company_location_id.toString();
      const isChecked = validCheckedLocationIds.includes(locationIdStr);

      return (
        <div key={location.company_location_id} className="flex items-center">
          <input
            id={`location-${location.company_location_id}`}
            type="checkbox"
            value={location.company_location_id}
            checked={isChecked}
            onChange={(e) =>
              handleCheckboxChange(locationIdStr, e.target.checked)
            }
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
          />
          <label
            htmlFor={`location-${location.company_location_id}`}
            className="ms-3 text-sm font-medium text-gray-900 dark:text-white"
          >
            {location.name}
          </label>
        </div>
      );
    });
  };

  const handleCheckboxChange = (locationId, isChecked) => {
    const locationIdStr = locationId.toString();

    setSelectedLocationIds((prevIds) => {
      // Ensure prevIds is an array
      const currentIds = Array.isArray(prevIds) ? prevIds : [];

      let newIds;
      if (isChecked) {
        // Add the ID if it's not already in the array
        newIds = currentIds.includes(locationIdStr)
          ? currentIds
          : [...currentIds, locationIdStr];
      } else {
        // Remove the ID if it exists
        newIds = currentIds.filter((id) => id !== locationIdStr);
      }

      // Update getHandleIconData with the new selection
      setGetHandleIconData((prev) => ({
        ...prev,
        // Create the JSON string directly without escaping
        company_location_id: newIds.length
          ? `[${newIds.map((id) => `"${id}"`).join(",")}]`
          : null,
      }));

      return newIds;
    });
  };

  // Initialize selectedLocationIds when editing user
  useEffect(() => {
    if (getHandleIconData.company_location_id) {
      try {
        const locationIds = JSON.parse(getHandleIconData.company_location_id);
        setSelectedLocationIds(locationIds.map((id) => id.toString()));
      } catch (e) {
        console.error("Error parsing initial location IDs:", e);
        setSelectedLocationIds([]);
      }
    } else {
      setSelectedLocationIds([]);
    }
  }, [getHandleIconData.company_location_id]);

  const handleEditIcon = (
    usersid,
    email,
    first_name,
    last_name,
    phone,
    usertype,
    system_notifications,
    is_read_only,
    alerts_access,
    alerts,
    company_location_id,
    company_id
  ) => {
    // Parse company_location_id immediately when setting the data
    let parsedLocationIds = [];
    try {
      if (company_location_id === null) {
        // If null, use empty array
        parsedLocationIds = [];
      } else if (company_location_id) {
        const parsed = JSON.parse(company_location_id);
        // Ensure we have an array
        parsedLocationIds = Array.isArray(parsed) ? parsed : [parsed];
      }
    } catch (e) {
      console.error("Error parsing location IDs:", e);
      parsedLocationIds = [];
    }

    // Convert all IDs to strings
    const locationIdsAsStrings = parsedLocationIds.map((id) => id.toString());
    setSelectedLocationIds(locationIdsAsStrings);

    setGetHandleIconData({
      usersid,
      email,
      first_name,
      last_name,
      phone,
      usertype,
      system_notifications,
      is_read_only,
      alerts_access,
      alerts: alerts ? JSON.parse(alerts) : [],
      company_location_id,
      company_id,
    });
  };

  const toggleResetPass = async (
    first_name,
    last_name,
    usersid,
    emailResetPass
  ) => {
    const result = await Swal.fire({
      title: "Do you want to send a password reset link to this user?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    });

    if (result.isConfirmed) {
      try {
        const response = await ApiLink.post("/api/send-password-reset-link", {
          email,
          password,
          usersid,
        });

        if (response.status === 200) {
          setResetPassDetails({
            resetPassEmail: emailResetPass,
            resetPassfirstName: first_name,
            resetPasslastName: last_name,
            resetPassUserID: usersid,
            resetPassVerifyCode: response.data.Data.verification_code,
          });

          Swal.fire({
            title: "Password Reset Link Sent Successfully",
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          setResetPass(true);
        }
      } catch (error) {
        Swal.fire({
          title: "Error sending reset link",
          icon: "error",
          toast: true,
          timer: 4000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    }
  };

  const handleUnlockUser = async (first_name, last_name, usersid) => {
    const result = await Swal.fire({
      title: "Do you want to enable this user?",
      text: `User "${first_name} ${last_name}" will be restored.`,
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    });

    if (result.isConfirmed) {
      try {
        const response = await ApiLink.post("/api/restore-user", {
          email,
          password,
          usersid,
        });

        if (response.status === 200) {
          if (response.data.Data.success) {
            Swal.fire({
              title: "User Enabled Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            fetchUserList();
          } else {
            Swal.fire({
              title: "Error Occurred. Please try again later.",
              icon: "warning",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        console.error("Error unlocking user:", error);
      }
    }
  };

  const handleDeleteUser = async (first_name, last_name, usersid) => {
    const result = await Swal.fire({
      title: "Do you want to delete this user?",
      text: `"${first_name} ${last_name}" will be deleted permanently.`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    });

    if (result.isConfirmed) {
      try {
        const response = await ApiLink.post("/api/delete-user", {
          email,
          password,
          usersid,
        });

        if (response.status === 200) {
          if (response.data.Data.is_valid) {
            Swal.fire({
              title: "User Deleted Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            fetchUserList();
          } else {
            Swal.fire({
              title: "Error Occurred. Please try again.",
              icon: "warning",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleRestoreUser = async (first_name, last_name, usersid) => {
    const result = await Swal.fire({
      title: "Do you want to restore this user?",
      text: `User "${first_name} ${last_name}" will be restored.`,
      showCancelButton: true,
      confirmButtonText: "Restore",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    });

    if (result.isConfirmed) {
      try {
        const response = await ApiLink.post("/api/restore-user", {
          email,
          password,
          usersid,
        });

        if (response.status === 200) {
          if (response.data.Data.success) {
            Swal.fire({
              title: "User Restored Successfully",
              icon: "success",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
            fetchUserList();
          } else {
            Swal.fire({
              title: "Error Occurred. Please try again later.",
              icon: "warning",
              toast: true,
              timer: 4000,
              position: "top-right",
              timerProgressBar: true,
              showConfirmButton: false,
            });
          }
        }
      } catch (error) {
        console.error("Error restoring user:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGetHandleIconData((prev) => ({ ...prev, [name]: value }));
  };

  const handEditSubmit = async (event) => {
    event.preventDefault();

    const result = await Swal.fire({
      title: "Do you want to update this user?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#FF0000",
    });

    if (result.isConfirmed) {
      try {
        const requestData = {
          email,
          password,
          usersid: getHandleIconData.usersid,
          company_id: getHandleIconData.company_id,
          user_email: getHandleIconData.email,
          phone: getHandleIconData.phone,
          system_notifications: getHandleIconData.system_notifications,
          usertype: getHandleIconData.usertype,
          first_name: getHandleIconData.first_name,
          last_name: getHandleIconData.last_name,
          alerts_access: getHandleIconData.alerts_access,
          is_read_only: getHandleIconData.is_read_only,
          alerts: getHandleIconData.alerts,
          company_location_id: JSON.parse(
            getHandleIconData.company_location_id
          ),
        };
        console.log(requestData);
        const response = await ApiLink.post("/api/update-user", requestData);

        if (response.status === 200) {
          Swal.fire({
            title: "User Updated Successfully",
            icon: "success",
            toast: true,
            timer: 4000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          setEditUsers(false);
          fetchUserList();
        }
      } catch (error) {
        console.error("Error updating user:", error);
      }
    }
  };

  const handleAlertChange = (alertAbbr) => {
    setGetHandleIconData((prev) => {
      const currentAlerts = prev.alerts || [];
      const newAlerts = currentAlerts.includes(alertAbbr)
        ? currentAlerts.filter((abbr) => abbr !== alertAbbr)
        : [...currentAlerts, alertAbbr];

      return {
        ...prev,
        alerts: newAlerts,
      };
    });
  };

  // Add this useEffect to fetch locations when edit modal opens
  useEffect(() => {
    const fetchLocations = async () => {
      console.log(
        "selectedCompanyName.company_id" + selectedCompanyName.company_id
      );
      if (selectedCompanyName.company_id) {
        try {
          const response = await ApiLink.post("/api/company-locations", {
            email,
            password,
            company_id: selectedCompanyName.company_id,
          });
          console.log(response.data.Data.Company_location);
          if (response.status === 200) {
            if (Array.isArray(response.data.Data.Company_location)) {
              setLocationListData(response.data.Data.Company_location);
            }
          }
        } catch (error) {
          console.error("Error fetching locations:", error);
        }
      }
    };

    fetchLocations();
  }, [editUsers, selectedCompanyName.company_id]);

  const renderCompanyLocationNames = (companyLocationId) => {
    // Check if companyLocationId is null
    if (!companyLocationId) {
      return "All Locations";
    }

    try {
      // Parse the company_location_id string into an array
      const locationIds = JSON.parse(companyLocationId);
      // console.log(locationIds);
      // Find the names of the locations based on the IDs
      //console.log(locationListData);
      const locationNames = locationListData
        .filter((location) =>
          locationIds.includes(location.company_location_id.toString())
        )
        .map((location) => (
          <Link
            key={location.company_location_id}
            to={`/location/${location.company_location_id}`}
            className=" hover:underline  text-black dark:text-white block"
          >
            {location.name}
          </Link>
        ));

      if (locationNames.length === 0) {
        return "No locations assigned";
      }

      return <div className="flex flex-col gap-1">{locationNames}</div>;
    } catch (error) {
      console.error("Error parsing location IDs:", error);
      return "Error displaying locations";
    }
  };

  return (
    <div
      className={`min-h-screen bg-gray-100 dark:bg-gray-700 transition-all duration-300 p-4 mt-20`}
      style={{ marginLeft: sideBarOpen ? "16rem" : "0" }}
    >
      <div className="p-4 bg-white shadow-md rounded-md dark:bg-gray-800">
        <div className="flex justify-between">
          <div className="p-1 mt-1 text-xl font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Sustainable Sprinklers Users
          </div>
          {is_read_only !== 1 && (
            <div>
              <AddViewUsers fetchUserList={fetchUserList} />
            </div>
          )}
        </div>

        <div className="flex flex-col py-2 w-36 ml-2">
          <label
            htmlFor="status"
            className="block mb-0 text-sm font-semibold text-gray-900 dark:text-white"
          >
            Status
          </label>
          <select
            id="status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            disabled={loading}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="1">Enabled</option>
            <option value="2">Pending</option>
            <option value="3">Deleted</option>
            <option value="0">Disabled</option>
          </select>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full border border-black dark:border-white animate__animated animate__fadeIn text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 leading-4">
            <thead
              className={`text-sm uppercase leading-4`}
              style={{
                backgroundColor: tableHeaderColor,
                color: tableHeaderTextColor,
              }}
            >
              <tr>
                <th scope="col" className="px-6 py-3 border border-black">
                  Name / Login ID
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Locations
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  User Type
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 border border-black">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="animate__animated animate__fadeIn text-sm text-black dark:text-white leading-4">
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                    </div>
                  </td>
                </tr>
              ) : userListData.length > 0 ? (
                userListData.map((user) => (
                  <tr key={user.usersid} className="border-b">
                    <td className="px-6 py-4 border">
                      <div>
                        <div>{`${user.first_name} ${user.last_name}`}</div>
                        <div>{user.email}</div>
                      </div>
                    </td>
                    <td className="px-6 py-4 border ">
                      {renderCompanyLocationNames(user.company_location_id)}
                    </td>
                    <td className="px-6 py-4 border">{user.phone}</td>
                    <td className="px-6 py-4 border">
                      {USER_TYPES[user.usertype]}
                      {user.is_read_only === 1 && <div>Read Only</div>}
                    </td>
                    <td className="px-6 py-4 border">
                      <div className="flex items-center">
                        <div
                          className={`status p-2.5 rounded-md inline-block ${
                            user.status === 0
                              ? "bg-red-300 text-red-900"
                              : user.status === 1
                              ? "text-white bg-[#74D348]"
                              : user.status === 2
                              ? "bg-blue-300 text-blue-900"
                              : user.status === 3
                              ? "bg-red-300 text-red-900"
                              : ""
                          }`}
                        >
                          {user.status === 0
                            ? "Disabled"
                            : user.status === 1
                            ? "Active"
                            : user.status === 2
                            ? "Pending"
                            : user.status === 3
                            ? "Deleted"
                            : ""}
                        </div>

                        {user.alerts_access === 1 && (
                          <div className="warning p-1 rounded-md ml-2 bg-orange-400 group relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="white"
                              className="size-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                              />
                            </svg>
                            <span className="tooltip invisible group-hover:visible absolute z-10 bg-gray-800 text-white text-xs rounded py-1 px-2 -mt-10 -ml-16">
                              Alerts Enabled
                            </span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 border">
                      <div className="flex items-center justify-start gap-3">
                        {is_read_only !== 1 && (
                          <button
                            onClick={() => {
                              handleEditIcon(
                                user.usersid,
                                user.email,
                                user.first_name,
                                user.last_name,
                                user.phone,
                                user.usertype,
                                user.system_notifications,
                                user.is_read_only,
                                user.alerts_access,
                                user.alerts,
                                user.company_location_id,
                                user.company_id
                              );
                              setEditUsers(true);
                            }}
                            className="edit p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="blue"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                        )}

                        {userType !== 2 && is_read_only !== 1 && (
                          <button
                            className="email p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                            onClick={() =>
                              toggleResetPass(
                                user.first_name,
                                user.last_name,
                                user.usersid,
                                user.email
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6 dark:hover:text-black"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                              />
                            </svg>
                          </button>
                        )}

                        {user.status === 2 &&
                          userType !== 2 &&
                          is_read_only !== 1 && (
                            <button
                              className="unlock p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full dark:hover:text-black"
                              onClick={() =>
                                handleUnlockUser(
                                  user.first_name,
                                  user.last_name,
                                  user.usersid
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6 text-[#34495E]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                              </svg>
                            </button>
                          )}

                        {is_read_only !== 1 && selectedStatus !== "3" && (
                          <button
                            className="p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full"
                            onClick={() =>
                              handleDeleteUser(
                                user.first_name,
                                user.last_name,
                                user.usersid
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="red"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        )}

                        {user.status === 3 &&
                          userType !== 2 &&
                          is_read_only !== 1 && (
                            <button
                              className="p-1 hover:bg-gray-300 dark:hover:bg-white rounded-full dark:hover:text-black"
                              onClick={() =>
                                handleRestoreUser(
                                  user.first_name,
                                  user.last_name,
                                  user.usersid
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    {noUserMessage || "No users found"}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {editUsers && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {modalLoading ? (
            <div className="flex justify-center items-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="relative p-4 w-full max-w-fit h-full md:h-auto">
              <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Edit Sustain Sprinkler User
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => setEditUsers(false)}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>

                <form
                  id="editUserForm"
                  className="flex gap-4"
                  onSubmit={handEditSubmit}
                >
                  <div className="flex-1 flex flex-col">
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={getHandleIconData.email || ""}
                          onChange={handleInputChange}
                          id="email"
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          value={getHandleIconData.first_name || ""}
                          onChange={handleInputChange}
                          id="first_name"
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Phone
                        </label>
                        <input
                          type="text"
                          name="phone"
                          value={getHandleIconData.phone || ""}
                          onChange={handleInputChange}
                          id="phone"
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="last_name"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          value={getHandleIconData.last_name || ""}
                          onChange={handleInputChange}
                          id="last_name"
                          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="system_notifications"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          System Notifications
                        </label>
                        <select
                          id="system_notifications"
                          name="system_notifications"
                          value={getHandleIconData.system_notifications || "1"}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="alerts_access"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Enable Alerts
                        </label>
                        <select
                          id="alerts_access"
                          name="alerts_access"
                          value={getHandleIconData.alerts_access || "0"}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="usertype"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          User Type
                        </label>
                        <select
                          id="usertype"
                          name="usertype"
                          value={getHandleIconData.usertype || ""}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value="">Select User Type</option>
                          {userType === 1 ? (
                            <option value="1">Super Admin</option>
                          ) : (
                            ""
                          )}
                          <option value="4">Account User</option>
                          <option value="2">Account Admin</option>
                        </select>
                      </div>
                      <div>
                        <label
                          htmlFor="is_read_only"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Read Only
                        </label>
                        <select
                          id="is_read_only"
                          name="is_read_only"
                          value={getHandleIconData.is_read_only || "0"}
                          onChange={handleInputChange}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Select Locations
                      </label>
                      <div className="grid grid-cols-2 gap-4 overflow-y-auto max-h-48 border rounded-lg p-2">
                        {locationListData.length > 0 ? (
                          locationListData.map((location) => (
                            <div
                              key={location.company_location_id}
                              className="flex items-center"
                            >
                              <input
                                id={`location-${location.company_location_id}`}
                                type="checkbox"
                                value={location.company_location_id}
                                checked={selectedLocationIds.includes(
                                  location.company_location_id.toString()
                                )}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    location.company_location_id,
                                    e.target.checked
                                  )
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label
                                htmlFor={`location-${location.company_location_id}`}
                                className="ms-3 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                {location.name}
                              </label>
                            </div>
                          ))
                        ) : (
                          <div>No locations available</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-96">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-2 py-3">
                            Text
                          </th>
                          <th scope="col" className="px-2 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-2 py-3">
                            Alert
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[
                          { id: "M", label: "Manual Run Detected [info]" },
                          { id: "SD", label: "Clock Skew or Prog. Mod [low]" },
                          { id: "MM", label: "Multiple Manual Events [high]" },
                          { id: "NF", label: "No Feedback [high]" },
                          { id: "UW", label: "Unauthorized Watering [high]" },
                        ].map((alert) => (
                          <tr
                            key={alert.id}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <td className="w-4 p-4">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  disabled
                                  className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={getHandleIconData.alerts?.includes(
                                    alert.id
                                  )}
                                  onChange={() => handleAlertChange(alert.id)}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                              </div>
                            </td>
                            <td className="pl-1 py-4">{alert.label}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </form>

                <div className="absolute bottom-4 right-4 flex justify-end gap-2">
                  <button
                    type="button"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                    style={{ backgroundColor: "red" }}
                    onClick={() => setEditUsers(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    form="editUserForm"
                    className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                    style={{ backgroundColor: "#009D01" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
