import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../../../components/context/themeContext/ThemeContext";
import states from "states-us";
import { useSelector } from "react-redux";
import ApiLink from "../../../../../components/api/ApiLink";
import { useSelectedCompany } from "../../../../../components/context/apiContext/SelectedCompanyContext";

const AccountsViewUsersAddUser = ({ fetchUserList }) => {
  const Swal = require("sweetalert2");

  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);

  const { color, textColor, backgroundColor } = useContext(ThemeContext);

  const { selectedCompanyName, locationsViewUsers } = useSelectedCompany();
  const [AddUser, setAddUser] = useState(false);
  const usertype = useSelector((state) => state.auth.userType);

  const [locationData, setLocationData] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [systemNotifications, setSystemNotifications] = useState(1);
  const [enableAlerts, setEnableAlerts] = useState(1);
  const [userType, setUserType] = useState(1);
  const [ReadOnly, setReadOnly] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [companyLocationIds, setCompanyLocationIds] = useState([]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await ApiLink.post("/api/company-locations", {
          email: email,
          password: password,
          company_id: selectedCompanyName.company_id,
        });

        if (response.status === 200) {
          const data = response.data.Data?.Company_location || []; // Extract the Company_location array

          if (Array.isArray(data)) {
            setLocationData(data);
          } else {
            setLocationData([]);
          }

          console.log("location data: ", data);
        }
      } catch (error) {
        console.error("Error:", "There is currently no charts data available");
      }
    };

    fetchLocation();
  }, [email, password, selectedCompanyName.company_id]);

  const toggleAddUser = () => {
    setAddUser(!AddUser);
  };
  const closeAddUser = () => {
    setAddUser(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setSystemNotifications(1);
    setEnableAlerts(1);
    setUserType(1);
    setReadOnly(1);
    setAlerts([]);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");

    if (phoneNumber.length <= 3) {
      return phoneNumber;
    } else if (phoneNumber.length <= 6) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  const handleCheckboxChange = (e) => {
    const alertId = e.target.id;
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the alert ID to the array if checked
      setAlerts((prev) => [...prev, alertId]);
    } else {
      // Remove the alert ID if unchecked
      setAlerts((prev) => prev.filter((alert) => alert !== alertId));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Email: ", emailAddress);
    console.log("First Name: ", firstName);
    console.log("Last Name: ", lastName);
    console.log("Phone: ", phone);
    console.log("System Notifications: ", systemNotifications);
    console.log("Enable Alerts: ", enableAlerts);
    console.log("User Type: ", userType);
    console.log("Read Only: ", ReadOnly);

    console.log("alerts:", JSON.stringify(alerts));
    console.log("company_location_id:", JSON.stringify(companyLocationIds));

    Swal.fire({
      title: "Do you want to add this user?",
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await ApiLink.post("/api/add-user", {
            email: email,
            password: password,
            user_email: emailAddress,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            usertype: userType,
            system_notifications: systemNotifications,
            is_read_only: ReadOnly,
            alerts_access: enableAlerts,
            alerts: alerts,
            company_location_id: companyLocationIds,
            company_id: selectedCompanyName.company_id,
          });

          if (response.status === 200) {
            console.log(response.data);

            // if (response.data.Data.result === 0) {
            //   Swal.fire({
            //     title: `${response.data.Data.message}`,
            //     icon: "warning",
            //     toast: true,
            //     timer: 4000,
            //     position: "top-right",
            //     timerProgressBar: true,
            //     showConfirmButton: false,
            //   });
            // } else if (response.data.Data.result === 1) {
            const userid = response.data.Data.usersid;
            try {
              const zohoResponse = await ApiLink.post(
                "/api/zoho-crm-add-contact",
                {
                  email: emailAddress,
                  phone: phone,
                  system_notifications: systemNotifications,
                  usertype: userType,
                  first_name: firstName,
                  last_name: lastName,
                  alerts_access: enableAlerts,
                  alerts: alerts,
                  is_read_only: ReadOnly,
                  userid: userid,
                  company_id: selectedCompanyName.company_id,
                }
              );

              console.log("Zoho Response: ", zohoResponse.data);

              if (zohoResponse.data.data[0].code === "SUCCESS") {
                Swal.fire({
                  title: "User Added Successfully",
                  icon: "success",
                  toast: true,
                  timer: 4000,
                  position: "top-right",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });

                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setSystemNotifications(1);
                setEnableAlerts(1);
                setUserType(1);
                setReadOnly(1);
                setAlerts([]);
                setCompanyLocationIds([]);
                setAddUser(false);
                fetchUserList();
              }
            } catch (error) {}
            //}
          }
        } catch (error) {}
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const toggleBodyScroll = (disable) => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    toggleBodyScroll(AddUser);
    return () => toggleBodyScroll(false);
  }, [AddUser]);

  const handleCheckboxLocation = (e) => {
    const locationId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the location ID to the array if checked
      setCompanyLocationIds((prev) => [...prev, locationId]);
    } else {
      // Remove the location ID if unchecked
      setCompanyLocationIds((prev) => prev.filter((id) => id !== locationId));
    }
  };

  return (
    <>
      <button
        className={`px-3 py-1.5 text-sm rounded-md bg-${color}-200 hover:bg-${color}-400 text-${color}-800 font-semibold`}
        style={{ backgroundColor: `${backgroundColor}`, color: `${textColor}` }}
        onClick={toggleAddUser}
      >
        + Add Users
      </button>

      {AddUser && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full font-inter"
          style={{
            // backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="relative p-4 w-full max-w-fit h-full md:h-auto">
            {/* <!-- Modal content --> */}
            <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              {/* <!-- Modal header --> */}
              <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Add Sustain Sprinkler User
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="defaultModal"
                  onClick={closeAddUser}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <form
                className="flex flex-col md:flex-row gap-4"
                onSubmit={handleSubmit}
              >
                <div className="leftSide grid gap-4 mb-4 sm:grid-cols-2">
                  <div>
                    <label
                      for="emailAddress"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="emailAddress"
                      onChange={(e) => setEmail(e.target.value)}
                      value={emailAddress}
                      id="emailAddress"
                      required
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="fname"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      name="fname"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                      id="fname"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone
                    </label>

                    <input
                      type="text"
                      name="phone"
                      onChange={(e) =>
                        setPhone(formatPhoneNumber(e.target.value))
                      }
                      required
                      value={phone}
                      id="phone"
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="lname"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lname"
                      id="lname"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    />
                  </div>
                  <div>
                    <label
                      for="systemNotifcations"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      System Notifications
                    </label>
                    <select
                      id="systemNotifcations"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => setSystemNotifications(e.target.value)}
                      value={systemNotifications}
                    >
                      <option value="1" selected>
                        Yes
                      </option>
                      <option value={"0"}>No</option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="enableAlerts"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Enable Alerts
                    </label>
                    <select
                      id="enableAlerts"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => setEnableAlerts(e.target.value)}
                      value={enableAlerts}
                    >
                      <option value="1" selected>
                        Yes
                      </option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div>
                    <label
                      for="userType"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      User Type
                    </label>
                    <select
                      id="userType"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => setUserType(e.target.value)}
                      value={userType}
                      required
                    >
                      <option value="0" selected>
                        Select User Type
                      </option>
                      <option value="4">Account User</option>
                      <option value="2">Account Admin</option>
                      {usertype !== 2 && <option value="1">Super Admin</option>}
                    </select>
                  </div>
                  <div>
                    <label
                      for="readOnly"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Read Only
                    </label>
                    <select
                      id="readOnly"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => setReadOnly(e.target.value)}
                      value={ReadOnly}
                    >
                      <option value="1" selected>
                        Yes
                      </option>
                      <option value="0">No</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="selectLocations"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Locations
                    </label>

                    <div className="grid gap-4 mb-4 sm:grid-cols-4">
                      {locationData.length > 0 ? (
                        locationData.map((location) => (
                          <div
                            key={location.company_location_id}
                            className="flex items-center"
                          >
                            <input
                              id={`checkbox-${location.company_location_id}`}
                              type="checkbox"
                              value={location.company_location_id}
                              onChange={handleCheckboxLocation}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor={`checkbox-${location.company_location_id}`}
                              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {location.name}
                            </label>
                          </div>
                        ))
                      ) : (
                        <div className="text-center py-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-8 h-8 mx-auto text-gray-500 dark:text-gray-400"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <p className="animate__animated animate__fadeIn mt-2 text-sm text-gray-500 dark:text-gray-400">
                            No available location
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rightSide flex flex-col justify-between w-full md:w-auto">
                  <table className="w-fit text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-2 py-3">
                          Text
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Email
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Alert
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-3"
                              type="checkbox"
                              disabled
                              className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-3"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <input
                              id="M"
                              type="checkbox"
                              onChange={handleCheckboxChange}
                              checked={alerts.includes("M")}
                              className="M w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="M" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-1 py-4">
                          Manual Run Detected [info]
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-3"
                              type="checkbox"
                              disabled
                              className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-3"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <input
                              id="SD"
                              type="checkbox"
                              checked={alerts.includes("SD")}
                              onChange={handleCheckboxChange}
                              className="SD w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="SD" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-1 py-4">
                          Clock Skew or Prog. Mod [low]
                        </td>
                      </tr>{" "}
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-3"
                              type="checkbox"
                              disabled
                              className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-3"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <input
                              id="MM"
                              type="checkbox"
                              checked={alerts.includes("MM")}
                              onChange={handleCheckboxChange}
                              className="MM w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="MM" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-1 py-4">
                          {" "}
                          Multiple Manual Events [high]
                        </td>
                      </tr>{" "}
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-3"
                              type="checkbox"
                              disabled
                              className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-3"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <input
                              id="NF"
                              type="checkbox"
                              checked={alerts.includes("NF")}
                              onChange={handleCheckboxChange}
                              className="NF w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="NF" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-1 py-4"> No Feedback [high]</td>
                      </tr>{" "}
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              id="checkbox-table-search-3"
                              type="checkbox"
                              disabled
                              className="cursor-not-allowed w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              for="checkbox-table-search-3"
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <input
                              id="UW"
                              type="checkbox"
                              checked={alerts.includes("UW")}
                              onChange={handleCheckboxChange}
                              className="UW w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label for="UW" className="sr-only">
                              checkbox
                            </label>
                          </div>
                        </td>
                        <td className="pl-1 py-4">
                          {" "}
                          Unauthorized Watering [high]
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex items-center justify-end mt-4">
                    <button
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center me-2"
                      style={{ backgroundColor: "red" }}
                      onClick={closeAddUser}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="text-white font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center"
                      style={{ backgroundColor: "#009D01" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountsViewUsersAddUser;
